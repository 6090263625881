export const useSpokenLanguagesSentence = (x?: string[]) => {
  const { getReferentialOptions } = useReferentialStore()
  const getLanguageOption = (x: string) => getReferentialOptions('spoken_language_list').find(({ value }) => value === x)

  return computed(() => {
    if (!x) {
      return ''
    }
    return x.reduce((acc, cur, i, arr) => {
      const opt = getLanguageOption(cur)
      if (opt) {
        acc += opt.text
        if (i < arr.length - 1) {
          acc += ', '
        }
      }
      return acc
    }, '')
  })
}
